<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>在籍学员分析</a-breadcrumb-item>
            <a-breadcrumb-item>优惠券统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="12">
                        <credits :loading='loadingCO' :item ='couponSendData' title="优惠券发放统计" :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <creditsCosts :loading='loadingCO' :item ='couponCashData' title="优惠券消耗统计" :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import statistics from '@/views/bi/ranking/money/statistics'
import moment from 'moment'
import credits from '../integral/credits'
import creditsCosts from '../integral/creditsCosts'

    export default {
        name:'jftj',
        data() {
            return {
                loading: false,
                loadingC: false,
                loadingCO: false,
                couponSendData:{},
                couponCashData:{},
                creditsData:{},
                creditsCostsData:{},
                counts:[],
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    filter_type:'day'
                },
            }
        },
        mixins: [ranges],
        components:{
            credits,
            creditsCosts,
            statistics
        },
        created () {
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.toScreen()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj) {
                this.getCoupon(obj)
            },
            async getCoupon(obj){
                this.loadingCO = true
                let res = await this.$store.dispatch('analysisCouponAction', obj)
                this.couponSendData = res.data.send
                this.couponCashData = res.data.cash
                this.loadingCO = false
            },
            handleChange(value){
                this.paramsData.studio_id = value;
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen();
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>